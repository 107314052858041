@use '@angular/material' as mat;
@import '../../../libs/login/src/lib/login/login.component.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

@include mat.core();

$mat-primary-palette: (
  50: #007bff,
  100: #007bff,
  200: #007bff,
  300: #007bff,
  400: #007bff,
  500: #007bff,
  600: #007bff,
  700: #007bff,
  800: #007bff,
  900: #007bff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
  ),
);

$my-primary: mat.define-palette($mat-primary-palette);
//$my-primary: mat.define-palette(mat.$indigo-palette, A200, A100, A400);
$my-accent: mat.define-palette(mat.$pink-palette);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family:
          'Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
      ),
  )
);

@include mat.all-component-themes($my-theme);

.mat-mdc-button,
.mat-mdc-button:not(:disabled) {
  appearance: none;
  background-color: #1976d2;
  border: 0;
  border-radius: 4px;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 700;
  line-height: 0.5;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 250ms ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: fit-content;
}

.mat-mdc-form-field-infix,
.mat-mdc-form-field-focus-overlay,
.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  background-color: #fff !important;
  caret-color: #fff !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-bottom-color: #fff !important;
}

.mdc-text-field--filled .mdc-line-ripple::before {
  border-bottom-width: 0px !important;
}
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s !important;
}

.mat-mdc-button:hover,
.mat-mdc-button:not(:disabled):hover {
  background-color: #145ea8;
}

table {
  cursor: pointer;
}

th {
  font-weight: bold;
  color: black;
  min-width: 36px;
}

.mat-mdc-row:hover {
  background-color: #f6f7f7;
}

.mat-mdc-cell:hover {
  color: #1976d2;
}

.mat-mdc-header-cell:hover {
  color: #1976d2;
}

.destroy-link {
  color: #d32f2f;
}

.destroy-link:hover {
  color: #1976d2;
  text-decoration: none;
}

.mdc-data-table__table {
  min-width: 0px !important;
  width: 100%;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: #1976d2 !important;
  background-color: #1976d2 !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate='true']
  )
  ~ .mdc-checkbox__background {
  border-width: 1px !important;
  border-radius: 5px !important;
}

.admin-table-header {
  height: 80px;
  border-bottom: #e5e7eb 1pt solid;
  h1 {
    font-weight: bolder;
    font-size: 2rem;
  }
}

.table-label {
  p {
    color: #7b808c;
    font-size: 0.8em;
    font-weight: 400;
    letter-spacing: 0.0357em;
    position: relative;
    text-transform: uppercase;
    clear: left;
    float: left;
    margin-bottom: 1.5em;
    margin-top: 0.25em;
    text-align: right;
    width: calc(15% - 1rem);
    min-width: calc(15% - 1rem);
  }
}

.edit-fields {
  mat-label {
    color: #293f54;
    font-weight: 700;
    min-width: 17%;
    flex-direction: row;
    padding-top: 6px;
  }
  p {
    font-weight: bolder;
    color: red;
    padding-left: 7px;
  }

  .mat-mdc-form-field {
    width: 100% !important;
  }
}

.mdc-text-field--no-label .mat-mdc-form-field-infix {
  padding: 0px !important;
}

.login-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.mat-mdc-form-field-subscript-wrapper {
  height: 0px !important;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  padding-bottom: 15px !important;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled)
  .mat-mdc-text-field-wrapper {
  height: 30px;
}

.mdc-checkbox {
  margin-left: 5px !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  //background-color: #f6f7f7 !important;
}

.mat-mdc-button[disabled],
.mat-mdc-button.mat-mdc-button-disabled {
  background-color: #b2b5b7;
}

.nav-select {
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-focus-overlay,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #f6f7f7 !important;
    height: 45px;
  }
}

.nav-dropdown {
  display: block !important;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s !important;
}
