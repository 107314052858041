@use '@angular/material' as mat;
  



  h1{
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }

  h2{
    margin: 12px 0 12px 0;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }

  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

a{
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover{
  text-decoration: underline;
  cursor: pointer;
}

.login-form{
  width:100%;
  max-width: 500px;
}

.login-button {
  padding: 0.25rem 0.5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff !important;  
  width:80px;
}
